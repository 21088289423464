<template>
    <div>
        <TopPage title="Resumo" complement="e atividades" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TopPage from '@/components/TopPage.vue';

export default {
    name: 'Resume',
    components: {
        TopPage
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            password: '',
            passwordConfirm: '',
            email: ''
        };
    },
    computed: {
        ...mapGetters('auth', ['loggedIn'])
    },
    methods: {}
};
</script>

<style></style>
